import * as React from "react";
import "./style.css";

function SvgComponent(props) {
    return (
        <div className="svg-container">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 355"
                preserveAspectRatio="none"
                {...props}
                className="svg-hd"
            >
                <path fill="none" d="M999.45 0H0v165.72l379.95 132.46L999.45 0z" />
                <path
                    className="divider-fill"
                    d="M379.95 298.18l28.47 9.92L1000 118.75V0h-.55l-619.5 298.18zm112.09 39.07L1000 252.63V118.75L408.42 308.1l83.62 29.15z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M492.04 337.25L1000 252.63V118.75L408.42 308.1l83.62 29.15z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M530.01 350.49l20.22 4.51H1000V252.63l-507.96 84.62 37.97 13.24z"
                />
                <path
                    className="divider-fill"
                    d="M530.01 350.49l20.22 4.51H1000V252.63l-507.96 84.62 37.97 13.24z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M530.01 350.49l20.22 4.51H1000V252.63l-507.96 84.62 37.97 13.24z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M542.94 355h7.29l-20.22-4.51 12.93 4.51z"
                />
                <path
                    className="divider-fill"
                    d="M542.94 355h7.29l-20.22-4.51 12.93 4.51z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M542.94 355h7.29l-20.22-4.51 12.93 4.51z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M542.94 355h7.29l-20.22-4.51 12.93 4.51z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M379.95 298.18L0 165.72v66.59l353.18 78.75 26.77-12.88z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M353.18 311.06L0 232.31v71.86l288.42 38.06 64.76-31.17z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M353.18 311.06L0 232.31v71.86l288.42 38.06 64.76-31.17z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M380.28 317.11l28.14-9.01-28.47-9.92-26.77 12.88 27.1 6.05z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M380.28 317.11l28.14-9.01-28.47-9.92-26.77 12.88 27.1 6.05z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29l12.25-2.04-83.62-29.15-28.14 9.01 99.51 22.18z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29l12.25-2.04-83.62-29.15-28.14 9.01 99.51 22.18z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29l12.25-2.04-83.62-29.15-28.14 9.01 99.51 22.18z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2z"
                />
                <path
                    className="divider-fill"
                    d="M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2zm-241.59-8.26l9.46 1.25 82.4-26.37-27.1-6.05-64.76 31.17z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M288.42 342.23l9.46 1.25 82.4-26.37-27.1-6.05-64.76 31.17z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M288.42 342.23l9.46 1.25 82.4-26.37-27.1-6.05-64.76 31.17z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z"
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z"
                    opacity={0.5}
                />
                <path
                    className="divider-fill"
                    d="M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z"
                    opacity={0.3}
                />
                <path
                    className="divider-fill"
                    d="M288.42 342.23L0 304.17V355h385.18l-87.3-11.52-9.46-1.25z"
                />
            </svg>
        </div>
    )
}

export default SvgComponent;
