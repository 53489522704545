import * as React from "react"

function BlockSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={90}
            height={90}
            viewBox="0 0 90 90"
            fill="none"
            {...props}
        >
            <path
                d="M26.522 55.609c0 1.8 1.95 3.259 4.364 3.259.546 0 1.064-.082 1.54-.218.505-.15 1.01.259 1.01.79V70.31H1.363v-32.06h13.432c.546 0 .927.532.777 1.05a6.003 6.003 0 00-.245 1.678c0 2.413 1.459 4.363 3.259 4.363 1.8 0 3.273-1.963 3.273-4.363 0-.587-.082-1.16-.246-1.677a.806.806 0 01.778-1.05H33.45v13.527c0 .532-.505.927-1.01.79a5.607 5.607 0 00-1.54-.218c-2.414 0-4.364 1.46-4.364 3.273l-.014-.013z"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M65.496 50.755v19.554H33.437V59.441c0-.532-.505-.927-1.009-.791a5.611 5.611 0 01-1.54.218c-2.415 0-4.365-1.459-4.365-3.259 0-1.8 1.95-3.273 4.364-3.273.546 0 1.064.082 1.541.218.505.15 1.01-.259 1.01-.79V38.236h12.967c.546 0 .928.532.777 1.05a6.003 6.003 0 00-.245 1.678c0 2.413 1.46 4.363 3.26 4.363 1.8 0 3.272-1.963 3.272-4.363 0-.587-.082-1.16-.246-1.678a.807.807 0 01.778-1.05h7.909"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M74.25 19.105s-2.346 2.85-9.273 3.286a2.79 2.79 0 00-2.618 2.782c0 1.472 1.105 2.659 2.536 2.768 1.391.109 3.15.095 4.746-.273l-3.832 7.118-4.54 8.428a3.283 3.283 0 005.78 3.109l3.22-5.987a1.645 1.645 0 012.222-.668l3.123 1.677a4.75 4.75 0 006.436-1.936l6.723-12.49-14.537-7.828.014.014z"
                fill="#000"
            />
        </svg>
    )
}

export default BlockSvg
