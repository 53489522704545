import RoutesPages from "./router/route.js"
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <RoutesPages />
  );
}

export default App;
