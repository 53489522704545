import * as React from "react"

function BulbSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={90}
            height={90}
            viewBox="0 0 90 90"
            fill="none"
            {...props}
        >
            <path
                d="M54.372 16.086A23.508 23.508 0 0048 15.629c-11.771.728-21.343 10.342-22.028 22.128-.029.471-.043.929-.043 1.386M54.629 75.028a6.177 6.177 0 006.143-5.528l.371-3.586c.386-3.728 2.043-7.214 4.729-9.814 4.428-4.286 7.186-10.286 7.186-16.943 0-9.543-5.672-17.757-13.83-21.471M55.9 78.043H43.087a2.1 2.1 0 00-2.1 2.1V85.9c0 1.16.94 2.1 2.1 2.1h12.815a2.1 2.1 0 002.1-2.1v-5.758a2.1 2.1 0 00-2.1-2.1z"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.457 44.128c1 4.643 3.371 8.772 6.657 11.958 2.7 2.6 4.357 6.1 4.743 9.828l.371 3.586a6.177 6.177 0 006.143 5.528h.186a4.945 4.945 0 004.943-4.943V48.771a4.643 4.643 0 00-4.643-4.643H25.014a4.643 4.643 0 01-4.643-4.642V25.97"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M59.23 9.171v40.915a4.643 4.643 0 01-4.643 4.642H49.5M36.058 44.128l6.843-4.271a4.65 4.65 0 002.185-3.943v-8.157"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M44.3 49.886h-4.842a.786.786 0 00-.786.785v4.843c0 .434.352.786.786.786H44.3a.786.786 0 00.786-.786v-4.843a.786.786 0 00-.786-.785z"
                fill="#000"
            />
            <path
                d="M61.657 1.429h-4.843a.786.786 0 00-.786.785v4.843c0 .434.352.786.786.786h4.843a.786.786 0 00.786-.786V2.214a.786.786 0 00-.786-.785zM47.514 19.543h-4.843a.786.786 0 00-.785.785v4.843c0 .434.351.786.785.786h4.843a.786.786 0 00.786-.786v-4.843a.786.786 0 00-.786-.785zM22.771 18.043h-4.843a.786.786 0 00-.785.785v4.843c0 .434.351.786.785.786h4.843a.786.786 0 00.786-.786v-4.843a.786.786 0 00-.786-.785z"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default BulbSvg
