import * as React from "react"

function HumanSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={90}
            height={90}
            viewBox="0 0 90 90"
            fill="none"
            {...props}
        >
            <path
                d="M72.796 48v35.583c0 2.85-2.3 5.15-5.15 5.15H58.23M32.23 45.483c0-11.2 9.083-20.283 20.283-20.283 7.783 0 14.533 4.384 17.933 10.817M34.38 52.683l3.85 7.784c.35.7 1.016 1.2 1.8 1.316l8.6 1.25c1.966.283 2.75 2.7 1.333 4.084l-6.217 6.066a2.388 2.388 0 00-.683 2.117l1.466 8.567c.334 1.95-1.716 3.45-3.483 2.533l-7.683-4.034a2.405 2.405 0 00-2.234 0L23.446 86.4c-1.767.917-3.817-.567-3.483-2.534l1.466-8.566a2.373 2.373 0 00-.683-2.117l-6.217-6.067c-1.416-1.383-.633-3.8 1.334-4.083l8.6-1.25c.783-.117 1.45-.6 1.8-1.316l3.85-7.784c.883-1.783 3.416-1.783 4.3 0h-.034z"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.513 40.032l1.216 2.467a.77.77 0 00.567.417l2.717.4c.616.083.866.85.416 1.283l-1.966 1.917a.708.708 0 00-.217.666l.467 2.7a.764.764 0 01-1.1.8L11.18 49.4a.744.744 0 00-.7 0l-2.434 1.283c-.55.3-1.2-.183-1.1-.8l.467-2.7a.708.708 0 00-.217-.666L5.23 44.599a.751.751 0 01.416-1.283l2.717-.4a.707.707 0 00.567-.417l1.216-2.467c.284-.566 1.083-.566 1.35 0h.017z"
                fill="#000"
            />
            <path
                d="M52.514 22.267c5.688 0 10.3-4.611 10.3-10.3 0-5.689-4.612-10.3-10.3-10.3-5.689 0-10.3 4.611-10.3 10.3 0 5.688 4.611 10.3 10.3 10.3zM79.83 33.683l-19.867 19.85-11.95-11.95 3.516-3.517 8.434 8.433 16.35-16.333 3.516 3.517z"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default HumanSvg
