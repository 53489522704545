import * as React from "react"

function DartSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={90}
            height={90}
            viewBox="0 0 90 90"
            fill="none"
            {...props}
        >
            <path
                d="M44.992 82.685c-11.268 0-21.378-4.947-28.278-12.774M82.684 44.991c0 3.624-.512 7.132-1.472 10.458M44.991 7.316c19.508 0 35.558 14.826 37.494 33.838M7.316 44.992c0-19.31 14.528-35.227 33.242-37.428M44.991 13.653V.979M44.991 89.022V76.348M76.348 44.991h12.658M.979 44.991h12.657M49.227 82.452c13.8-1.538 25.366-10.54 30.545-22.883L56.227 44.99 40.772 57.616l-6.95-8.29-20.136 16.58.099.149a37.45 37.45 0 01-6.188-16.729M44.991 26.724v27.434"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M59.719 34.403l-11.947-6.9v13.783l11.947-6.883z" fill="#000" />
        </svg>
    )
}

export default DartSvg