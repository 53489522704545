import * as React from "react"

function ArrowSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={94}
            height={94}
            viewBox="0 0 94 94"
            fill="none"
            {...props}
        >
            <path
                d="M71.003 20.159l8.618-1.293-5.582 7.56 9.382-1.41L92 13.382 79.269 4l-9.382 1.41 7.56 5.582-7.678 1.156c-9.636 1.45-17.51 8.461-20.095 17.863l-8.618 31.397c-1.724 6.307-7.032 11.008-13.496 11.987L14.38 75.393l5.582-7.56-9.382 1.41L2 80.877l12.731 9.382 9.382-1.41-7.56-5.582 12.241-1.841c9.637-1.45 17.51-8.462 20.096-17.863l8.618-31.398c1.724-6.306 7.032-11.007 13.495-11.987v-.02z"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.672 26.251a24.64 24.64 0 0017.863 20.096l7.874 2.154 2.135-7.815-7.874-2.154a16.541 16.541 0 01-11.987-13.495l-1.117-7.365 6.875 5.073-1.41-9.382-11.634-8.579-9.382 12.731 1.41 9.382 6.091-8.265 1.156 7.619z"
                fill="#000"
            />
            <path
                d="M83.93 67.244c-1.45-9.617-8.461-17.51-17.843-20.095l-7.893-2.155-2.155 7.815 7.893 2.174A16.542 16.542 0 0175.92 68.46l1.528 10.146-8.226-6.072 1.41 9.382 11.634 8.579 9.382-12.732-1.41-9.382-5.092 6.895-1.215-8.05v.02z"
                stroke="#7D7D82"
                strokeWidth={1.13}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default ArrowSvg
